// This is the bottle neck for the application all sass and js files will be loaded through this file.
// config file is for webpack.
import config from '@/config';
import '../scss/main.scss';

// if you want to load new JS scripts u can import the files here
// import './components/file';
// or with variable:
// import variableName from './components/file';

import './important/object-fit.js';
import './klasmastr/menu.js';

// import './important/lazyload.js';
// import './optional/swiper.js';