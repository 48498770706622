if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    jQuery('.object-fit img').each(function () {
        var t = jQuery(this),
                s = 'url(' + t.attr('src') + ')',
                p = t.parent(),
                d = jQuery('<div class="ie-image"></div>');
        p.append(d);
        var bgSize = 'cover';
        if (p.hasClass('object-fit--contain')) {
            bgSize = 'contain';
        }
        d.css({
            'position': 'absolute',
            'top': '0',
            'left': '0',
            'height': '100%',
            'width': '100%',
            'background-size': bgSize,
            'background-repeat': 'no-repeat',
            'background-position': '50% 20%',
            'background-image': s,
        });

        t.hide();
    });
}
